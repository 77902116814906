@use '@/styles/utils/mixins.scss' as *;

.resultListingWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  padding-top: 6.25rem;
  width: 100%;
  height: 100%;

  @include tab() {
    gap: 3.75rem;
    padding-top: 3.75rem;
  }

  .sectionHeadingContainer {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  .sectionDescription {
    max-width: 43.5rem;
  }

  .resultTable,
  .resultMobileTable {
    width: 100%;
  }
  .resultTable {
    width: 100%;

    :global {
      .ant-table-thead > tr > th {
        font-size: 1.25rem;
        line-height: 1;
        font-weight: 500;
        text-align: left;
        background-color: transparent;
        border: transparent;
        padding: 1.5rem;

        &::before {
          content: none !important;
        }
      }

      .ant-table-tbody > tr > td {
        border-bottom: 1px solid $primaryAlt;
        padding: 1.5rem;
        font-size: 1rem;
        line-height: 1;
        font-weight: bold;
      }
    }
  }
  .resultMobileTable {
    .resultCollapseHeader {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
      padding: 1rem 1.5rem;
      padding-right: 3rem;
    }
    .customPanelHeader {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;

      .customPanelHeading {
        color: $dark;
        font-weight: 700;
      }
    }
    .panelBox {
      display: flex;
      flex-direction: column;
      gap: 3.125rem;
      font-size: 1rem;
      &Row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        &Title {
          text-align: left;
        }
        &Content {
          text-align: right;
          word-break: break-word;
        }
        .panelBoxRowContent {
          color: $dark;
          &:hover,
          &:focus,
          &:active {
            color: $primary;
          }
          font-weight: 400;
        }
      }
    }

    :global {
      .ant-collapse {
        border: none;
        background: none;

        .ant-collapse-item {
          border-radius: 0;
          margin-bottom: 1rem;

          .ant-collapse-header {
            font-size: 1rem;
            font-weight: bold;
            padding: 1.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $primaryAlt;
            border-radius: 0;
            &:hover {
              background-color: none;
            }
          }

          .ant-collapse-content {
            .ant-collapse-content-box {
              padding: 1.875rem 1.5rem;
            }
          }
        }

        .ant-collapse-arrow {
          right: 0;
          margin-left: auto;
        }
      }
    }
  }
}
